:root {
  --color-primary: #091e3c;
  --color-secondary: #253a60;
  --color-accent: #33445a;
  --color-success: #49df92;
  --color-warn: #fc4044;
  height: 100%;
}

html,
body,
#root,
.App,
.custom-bg-secondary,
.container {
  min-height: 100%;
}

input.no-buttons::-webkit-inner-spin-button,
input.no-buttons::-webkit-outer-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

input.no-buttons,
input.no-buttons:hover,
input.no-buttons:focus {
  -moz-appearance: textfield;
}

ul.list-team {
  list-style-type: circle;
}

ul.list-team > li {
  text-align: left;
}

.h-full {
  height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.custom-bg-primary {
  background-color: var(--color-primary) !important;
}

.custom-bg-accent {
  background-color: var(--color-accent);
}

.custom-bg-secondary {
  background-color: var(--color-secondary);
}

.shadow-dp2 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.shadow-dp3 {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12),
    0 1px 8px 0 rgba(0, 0, 0, 0.2);
}

.text-sm {
  font-size: 0.75rem;
}

.text-md {
  font-size: 0.875rem;
}

.text-base {
  font-size: 1rem;
}

.text-success {
  color: var(--color-success);
}

.text-accent {
  color: var(--color-accent);
}

.text-blue {
  color: #62bff7;
}

.text-orange {
  color: #e4c263;
}

.custom-bg-blue {
  background-color: #62bff7;
}

.custom-bg-orange {
  background-color: #e4c263;
}

.custom-bg-success {
  background-color: var(--color-success);
}

.border-0 {
  border: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.message-centered {
  padding: 2%;
}

.flex-completely-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.opacity-5 {
  opacity: 0.5;
}

.min-w-360px {
  min-width: 360px;
}

.min-w-420px {
  min-width: 420px;
}

.max-w-1200px {
  max-width: 1280px;
}

.width-full {
  width: 100%;
}

.fs-30px {
  font-size: 30px;
}

.circle {
  border-radius: 50%;
}

.circle.sm {
  height: 7px;
  width: 7px;
}

.circle.md {
  height: 10px;
  width: 10px;
}

.custom-table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0 1em;
  font-size: 14px;
  width: 100%;
}

.table.custom-table th,
.table.custom-table td {
  border-top: 0;
}
