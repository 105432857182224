.Header {
  background-color: var(--color-primary);
}

.Header-button {
  background-color: var(--color-secondary) !important;
  border: none !important;
  font-size: 0.75rem !important;
}

.NavLink {
  font-weight: bold;
  padding: 0.5rem 2rem;
  border-radius: 4px;
  font-size: 0.85rem;
}

.NavLink,
.NavLink:hover {
  background-color: #182c45;
  color: #8d8d8d;
  text-decoration: none;
}

.NavLink.active {
  background-color: var(--color-success);
  color: var(--color-secondary);
}
