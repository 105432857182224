.Earnings {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;

  &-label {
    margin: 0;
    color: #8d8d8d;
    font-size: 14px;
  }

  &-amount {
    font-size: 20px;
    margin: 0;
  }
}
