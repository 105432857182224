.FilterButton.is-active {
    background-color: var(--color-primary);
    color: white;
  }
  
  .Filters {
    border-bottom: 1px solid white;
    padding-bottom: 0.5em;
  }
  
  .PickerInput,
  .FilterButton {
    background-color: #3b4b62;
    color: #8d8d8d;
    font-weight: bold;
    font-size: 12px;
    border: 0;
    border-radius: 4px;
    padding: 0.5em 0.75em;
  }
  
  .PickerInput {
    width: 110px;
  }
  
  .Divider {
    background-color: rgba(250, 250, 250, 0.5);
    height: 20px;
    width: 1px;
  }
  