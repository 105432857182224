/**
 * DETAIL THUMBNAIL
 */

.Photo {
  display: block;
  position: relative;
  height: 120px;
  min-width: 120px;
  border: 4px solid var(--color-accent);
  width: 120px;

  &,
  &-overlay {
    border-radius: 50%;
  }

  &.is-editable {
    cursor: pointer;
  }

  &-overlay {
    opacity: 0;
    transition: opacity 0.14s ease;
    color: white;
    background: rgba(black, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &.is-editable:hover &-overlay {
    opacity: 1;
  }
}
